/**
 * Handle the fallback for finance types when no prices are available
 * Temporarily added code here until utility file is live - SB - 22/08/22
 */
((window, document) => {
  /**
   * Function to check whether a string contains numbers
   * @param {string} string - The string to check
   * @returns - Whether or not teh string contains a number
   */
  function hasNumber(string) {
    return /\d/.test(string);
  }

  /**
   * Build the fallback text depending on what type of finance is passed
   * @param {string} type - The finance stype
   * @returns - The fallback text
   */
  function buildFallbackText(type) {
    return ` <span class="font--medium text-size--smaller">${type} available ${
      type === 'Hire Purchase' ? '' : 'on all vans'
    }</span>
    <a class="text--primary heading--3"  href="">
      <svg class="icon margin-right-5" style="width: 15px; height: 15px;"><use xlink:href="#primaryNavPhone"></use></svg>
      <span data-calltracks-class="calltracks_vansdirect-central-call-centre-sales" data-default-number="443300400851">Call us</span>
    </a>
  `;
  }

  /**
   * Handle the fallback text
   */
  function handleFallbackText() {
    try {
      const tiles = document.querySelectorAll('.js-tile__column');

      tiles.forEach((tile) => {
        const tabContents = tile.querySelectorAll('.tabs__content');

        tabContents.forEach((content) => {
          if (content.id.includes('ch-')) {
            const price = content.querySelector('.js-cash-price');

            if (!hasNumber(price.innerHTML)) {
              const priceGroup = content.querySelector('.price-group');
              priceGroup.innerHTML = buildFallbackText('Contract Hire');
            }
          }

          if (content.id.includes('fl-')) {
            const price = content.querySelector('.js-fl-price');

            if (!hasNumber(price.innerHTML)) {
              const priceGroup = content.querySelector('.price-group');
              priceGroup.innerHTML = buildFallbackText('Finance Lease');
            }
          }
        });
      });
    } catch (err) {
      /* empty */
    }
  }

  vm.onload(handleFallbackText);
})(window, document);
