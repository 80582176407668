/**
 * Datalayers for home page carousel
 * https://app.asana.com/0/1201612549267367/1202273493318803
 */
((window, document) => {
  /**
   * Handle the carousel click, pass the vehicle data
   * to the dataLayer object
   * @param {string} make - The vehicle make
   * @param {string} model - The vehicle model
   * @param {string} name - The vehicle description
   * @param {string} price - The vehicle price
   */
  const handleCarouselClick = (make, model, name, price) => {
    // Push the datalayer for the clicked element if it is present.
    window.dataLayer.push({
      product: {
        make,
        model,
        name,
        cashPrice: price,
      },
    });
  };

  /**
   * Initialize a carousel tiles
   * checklist - The checklist grabs the href of the vehicle card
   * category - The category of the vehicle that it belongs to
   * * list - The list of where the vehicle card can be located
   * * position - The position(number) of the carousel card on the page starting from left to right
   * price - The price of the vehicle of the selected carousel card shown
   * name - The name of the vehicle of the selected carousel card
   * brand - The brand of the the vehicle of the selected carouwsel card
   * id - The id of the vehicle of the selected carousel card
   */
  const handleCarouselDataImpressions = () => {
    const carouselVehicles = [];
    const tiles = document.querySelectorAll('.js-tile__column');

    if (tiles) {
      tiles.forEach((tile, index) => {
        // Store the appropriate data from the elements to variables
        const checklist = tile.querySelector('.result-tile__title a').href.split('/');
        const category = `${checklist[3]}`;
        const list = `/${checklist[3]}/${checklist[4]}/`;
        const price = tile.querySelector('.js-cash-price').innerText;
        const name = tile.querySelector('.js-result-tile__title').innerText;
        const brand = tile.querySelector('.js-make').innerText;
        const model = tile.querySelector('.js-model').innerText;
        let id = tile.querySelector('.js-hp-element').href.split('-')[1];
        id = id.split('/');
        id = `${id[0]}`;

        const vehicle = {
          brand,
          category,
          id,
          list,
          name,
          position: index,
          price,
        };

        carouselVehicles.push(vehicle);

        tile.addEventListener('click', () => {
          handleCarouselClick(brand, model, name, price);
        });
      });
    }

    // Push the datalayer for the element if it is present.
    dataLayer.push({
      event: 'productView',
      ecommerce: {
        currencyCode: 'GBP',
        impressions: [carouselVehicles],
      },
    });
  };

  const initMutationObserver = () => {
    // When Carousel comes into view handleCarouselDataImpressions
    // function fires the observer stops firing
    const scroller = document.querySelector('.js-carousel-scroller');
    const observer = new IntersectionObserver((entries) => {
      [].forEach.call(entries, (entry) => {
        if (entry.isIntersecting) {
          handleCarouselDataImpressions();
        }
        observer.unobserve(scroller);
        observer.disconnect();
      });
    });

    observer.observe(scroller);
  };

  vm.onload(initMutationObserver);
})(window, document);
