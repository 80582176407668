((window, document) => {
  const assignAlignmentWrapper = (container) => {
    [...container.children].forEach((el) => {
      // Remove justify content classes to set the right one
      el.classList.remove('justify-items--flex-start', 'justify-items--flex-end');

      if (el.previousElementSibling) {
        // The elements are on different lines
        if (el.offsetLeft === el.previousElementSibling?.offsetLeft)
          el.classList.add('justify-items--flex-start');
        else el.classList.add('justify-items--flex-end');
      }
    });
  };

  const observer = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      assignAlignmentWrapper(entry.target);
    });
  });

  const vehiclePriceContainers = document.querySelectorAll('.js-price-promotional-offers-wrapper');

  vehiclePriceContainers.forEach((element) => {
    observer.observe(element);
    assignAlignmentWrapper(element);
  });
})(window, document);
